import React, { useEffect } from "react";
import commentBox from 'commentbox.io';

const CommentBox = () => {
  useEffect(() =>{
    let cm = commentBox('5740053242314752-proj');
  },[])
  
  return(
    <div className="commentbox" />
 )
}

export default CommentBox
